.bg-container {
  position: absolute;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  z-index: -100;
  $bg-size-ratio: 1.9;
  img#bg-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(102vh * #{$bg-size-ratio});
    height: 102vh;
    z-index: -2;
  }

  $bg-logo-size: 101vh;
  img#bg-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(0%, -50%);

    height: $bg-logo-size;
    width: $bg-logo-size;
  }

  #eternal-vikings-logo {
    position: absolute;

    top: 2rem;
    left: 2rem;
  }
}
