@import "../../../../../../assets/styles/setup/mixins";
@import "../../../../../../assets/styles/setup/variables";

.staking-grid-content-container {
  height: fit-content;
  padding: 0 $padding-medium $padding-small;
  overflow-y: scroll;
  overflow-x: hidden;
  @include grid(3, 0.5rem);
}
