@import "../../../../../../../assets/styles/styles.scss";

$claimable-gold-font-color: #e9d28f;
$gold-font-color: #e9d28f;

.currency-display-container {
  @include grid(3, 0);

  height: 100%;
  max-height: 15rem;

  mix-blend-mode: normal;
  box-shadow: 0px 47px 16px -34px rgba(0, 0, 0, 0.1),
    inset 0px 0px 36px rgba(240, 217, 180, 0.075);
  backdrop-filter: blur(3px);

  &.gold {
    .currency-display-left-section-container {
      .currency-wrapper {
        .currency-text-wrapper {
          p {
            &.currency-amount {
              background: linear-gradient(
                129.89deg,
                #fee01f -23.2%,
                #fab108 72.77%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-shadow: 0px 0px 43px rgba(253, 240, 8, 0.7);
            }
            &.currency-name {
              color: #fcc211;
            }
          }
        }
      }
    }
  }

  &.claimable-gold {
    .currency-display-left-section-container {
      .currency-wrapper {
        .currency-text-wrapper {
          p {
            &.currency-amount {
              color: $claimable-gold-font-color;
              text-shadow: 0px 0px 43px rgba(253, 240, 8, 0.2);
            }
            &.currency-name {
              color: $claimable-gold-font-color;
            }
          }
        }
      }
    }
    .currency-display-right-section-container {
      .helper-text-earn-rate {
        p {
          color: $claimable-gold-font-color;
        }
      }
    }
  }
}
