@import "../../../../../../assets/styles/setup/mixins";
@import "../../../../../../assets/styles/setup/variables";

.staking-grid-header {
  height: 3rem;
  @include grid(2, 0);

  &-right {
    .ev-generic-button-container {
      width: 7.25rem;
      min-width: 1rem;
      height: 2.25rem;
      p {
        font-size: 1.25rem;
      }
    }
  }
}
