@import "../../assets/styles/setup/variables";

.popup-container {
  position: absolute;

  width: 100vw;
  height: 100vh;

  z-index: 9999;

  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: $bg-blur-soft;

  p,
  h2,
  h3 {
    text-align: center;
  }

  .popup-wrapper {
    background-color: $bg-color;

    h3 {
      color: $font-secondary-color;
      max-width: 40rem;
    }

    .ev-generic-button-container {
      min-width: 10rem;

      &.btn-dark {
        border-color: $font-secondary-color;
        p {
          color: $font-secondary-color;
        }
      }
    }
  }
}
