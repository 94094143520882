@import "../../../../assets/styles/setup/variables";

[class^="nav-link"] {
  height: 100%;
}
.nav-link {
  &-active {
    border-bottom: 1px solid $font-secondary-color;
    color: $font-secondary-color;
  }
  &-disabled {
    p {
      color: $font-disabled-color;
    }

    &:hover {
      cursor: default;
    }
  }
}
