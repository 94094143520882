@import "../../../../assets/styles/styles.scss";

.layout-staking-grids-container {
  height: 80%;

  .staking-grids-content {
    @include grid(2, $spacing-small);

    height: 100%;
  }
}
