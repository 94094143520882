@import "../setup/mixins";

* {
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: "Primary", sans-serif;
  color: $font-primary-color;
  font-size: 81.25%; // (16px / 13px) * 100 = 81.25% this defines a base font size for using rem units.
  direction: ltr;
  letter-spacing: 0.06rem;

  width: 100vw;
  height: 100vh;
}

body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}

header {
  height: $header-h;
}

main {
  height: calc(100% - #{$header-h});
  width: 77%;
  max-width: 93rem;
}

p,
h1,
h2,
h3,
h3,
h5,
h6 {
  margin: 0;
  display: inline-block;
}
ul {
  list-style: none;
  padding-left: 0;
}
button {
  cursor: pointer;
  font-family: inherit;
  color: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}

@include for-huge-desktop-layout {
  html {
    font-size: 93.75%;
  }
}
@include for-big-desktop-layout {
  html {
    font-size: 81.25%;
  }
}
@include for-desktop-layout {
  html {
    font-size: 75%;
  }
}
@include for-tablet-layout {
  html {
    font-size: 81.25%;
  }
}
@include for-mobile-layout {
  html {
    font-size: 81.25%;
  }
}
