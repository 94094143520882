@import "../../../../../../assets/styles/setup/variables";

$x-image-size: 1.15rem;

.staking-grid-footer {
  background-color: $white-grey;
  margin-top: auto;
  padding: $padding-x-small $padding-small;
  .staking-grid-footer-right {
    p {
      color: #346d69;
    }

    .ev-generic-button-container {
      min-width: 5rem;
      border-color: black;
      p {
        color: black;
        font-size: $font-x-small-size;
      }
    }
  }
  .staking-grid-footer-left {
    img {
      width: $x-image-size;
      height: $x-image-size;
      color: black;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
