@import "../../../../assets/styles/setup/variables";

$polygon-size: 3rem;

.signout-popup {
  background-color: #192024;

  position: absolute;

  top: 107px;
  right: 26px;

  z-index: 10;

  text-align: center;

  box-shadow: $shadow-soft;

  h3 {
    width: 15rem;
  }

  p {
    width: 9rem;
  }

  .ev-generic-button-container {
    min-width: 3rem;
  }
}

img#polygon {
  position: absolute;
  box-shadow: $shadow-soft;
  top: 78px;
  right: 11px;
  width: $polygon-size;
  height: $polygon-size;
}
