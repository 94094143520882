@import "../../../../../../assets/styles/styles.scss";

$bribe-img-size: 13rem;
$eth-img-size: $font-small-size;

.mint-vikings-component-container {

    img {
        width: calc(#{$bribe-img-size} * 1);
        height: $bribe-img-size;
        border: white 1px solid;
        border-radius: 13px;
    }

    height: 80%;
    
    mix-blend-mode: normal;
    box-shadow: 0px 47px 16px -34px rgba(0, 0, 0, 0.1),
      inset 0px 0px 36px rgba(240, 217, 180, 0.075);
    backdrop-filter: blur(3px);

    .gold-icon-img {
        border: none;
        width: $eth-img-size;
        height: $eth-img-size;
    }

    
}
