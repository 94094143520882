@import "../../assets/styles/styles.scss";

.ev-generic-text- {
  &x-small {
    font-size: $font-x-small-size;
  }

  &small {
    font-size: $font-small-size;
  }

  &medium {
    font-size: $font-medium-size;
  }

  &large {
    font-size: $font-large-size;
  }

  &x-large {
    font-size: $font-x-large-size;
  }

  &huge {
    font-size: $font-huge-size;
  }
}

[class^="ev-generic-text-"] {
  white-space: pre-line;
}
