@import "../../assets/styles/setup/variables";

.app-background {
  position: absolute;
  z-index: -9999;

  width: 100vw;
  height: 100vh;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: $bg-color;
}
