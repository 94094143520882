@import "../../assets/styles/styles.scss";

.navbar-container {
  @include grid(3, 0);

  background-color: #192024;

  box-shadow: $shadow-soft;

  .ev-logo-container > img {
    margin-left: $spacing-medium;
  }
}
