@import "../../assets/styles/setup/variables";

.nft-card-container {
  background: $bg-color;
  border: $border-color;
  max-height: fit-content;

  &.highlighted {
    border: 1px solid #65fff4;
    box-shadow: 0px 0px 14px rgba(101, 255, 244, 0.4);
  }

  &:hover {
    cursor: pointer;
  }

  .nft-card-header {
    width: 100%;
    img {
      object-fit: fill;
      border-top-left-radius: $border-radius-narrow;
      border-top-right-radius: $border-radius-narrow;
    }
  }

  .nft-card-footer {
    p {
      min-height: $font-small-size;
    }
  }
}
