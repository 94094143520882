@import "../../../../assets/styles/styles.scss";

$img-size: 4.75rem;

.account-details-container {
  position: relative;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 7rem;
  }

  img {
    margin-right: $spacing-medium;
    height: $img-size;
    width: $img-size;

    &:hover {
      cursor: pointer;
    }
  }
}
