@import "../../../../assets/styles/styles.scss";

$bribe-img-size: 15rem;
$eth-img-size: $font-small-size;
$claimable-gold-font-color: #e9d28f;
$gold-font-color: #e9d28f;

.auction-component-container {

    img {
        width: calc(#{$bribe-img-size});
        height: $bribe-img-size;       
    }

    height: 93%;
    
    mix-blend-mode: normal;
    box-shadow: 0px 47px 16px -34px rgba(0, 0, 0, 0.1),
      inset 0px 0px 36px rgba(240, 217, 180, 0.075);
    backdrop-filter: blur(3px);

    .gold-icon-img {
        border: none;
        width: $eth-img-size;
        height: $eth-img-size;
    }

    .auction-section {
        @include grid(2, $spacing-small);

        input {
            background-color: transparent;
            padding: 10px;
            border: 2px solid white;
            border-top: none;
            border-left: none;
            border-right: none;
            color: $gold-font-color;
            font-size: 20px;
            text-align: center;
        }
    }
}
