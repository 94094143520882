$img-height: 10rem;

.currency-display-left-section-container {
  grid-column: span 2;

  height: 100%;

  .currency-wrapper {
    height: 50%;

    .image-wrapper {
      width: 9rem;
      position: relative;
      img {
        width: calc(1.1 * #{$img-height});
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-57%, -45%);
      }
    }
    .currency-text-wrapper {
    }
  }
  .cta-wrapper {
    height: 30%;
  }
}
