@font-face {
  font-family: Primary;
  src: url("../../fonts/Share-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Primary;
  src: url("../../fonts/Share-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Vikings;
  src: url("../../fonts/EternalVikings.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
