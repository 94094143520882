@import "./variables";

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@mixin flexCenterAlignStretch {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@mixin flexAlignCenterNoWrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

@mixin flexWrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin grid($columnsNum, $gap) {
  display: grid;
  grid-template-columns: repeat($columnsNum, 1fr);
  grid-gap: $gap;
}

/* Media queries Breakpoint mixins */

@mixin for-huge-desktop-layout {
  @media (min-width: #{$break-huge-screen}) {
    @content;
  }
}
@mixin for-big-desktop-layout {
  @media (min-width: #{$break-big-screen}) and (max-width: #{$break-huge-screen}) {
    @content;
  }
}
@mixin for-desktop-layout {
  @media (min-width: #{$break-desktop}) and (max-width: #{$break-big-screen}) {
    @content;
  }
}

@mixin for-tablet-layout {
  @media (min-width: #{$break-tablet}) and (max-width: #{$break-desktop}) {
    @content;
  }
}

@mixin for-mobile-layout {
  @media (min-width: #{$break-mobile}) and (max-width: #{$break-tablet}) {
    @content;
  }
}
@mixin for-short-screens {
  @media only screen and (max-height: 1010px) {
    @content;
  }
}
