@import "../../../../../../../assets/styles/setup/variables";

.currency-display-right-section-container {
  .helper-text-icon-button {
    position: relative;
    img {
      margin-bottom: auto;

      &:hover {
        cursor: help;
      }
    }
    .help-text-paragraph {
      display: flex;
      position: absolute;
      z-index: 9999999;
      top: -15px;
      left: -240px;
      background-color: $popup-bg-color;
      box-shadow: $shadow-soft;
      width: 17.5rem;
    }
  }
}
