@import "../../assets/styles/styles.scss";
.btn- {
  &light {
    background: #5dcec6;
    color: black;
    border: none;
  }
  &light:hover {
    background: rgb(255, 255, 255);
  }
  &dark {
    font-family: "Primary";
    color: white;
    background: rgba(0, 0, 0, 0);
    border: 1px solid white;
  }
  &dark:hover {
    background: rgba(66, 66, 66, 0.516);
  }
}
.ev-generic-button-container {
  border-radius: 2px;
  padding: 12px;
  font-family: "Primary";
  min-width: 17.25rem;
  height: 3rem;
}
